import { useContext, useEffect, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useMutation } from "@tanstack/react-query"
import { Close, CloudUpload } from "@mui/icons-material"
import {
    DialogActions,
    Button,
    DialogContent,
    CircularProgress,
    Typography,
    DialogTitle,
    Box,
    Grid,
    TextField,
    Tooltip,
    IconButton,
    Drawer,
} from "@mui/material"

import useAccessControl from "../../hooks/useAccessControl"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import useBreakPoints from "../../hooks/useBreakPoints"
import AuthContext from "../../context/authProvider"
import AddPrices from "../prices/addPrices"

interface AddProductsProps {
    openDialog: boolean
    handleCloseDialog: () => void
}
interface ProductForm {
    name: string
    product_logo: string
    description: string
}

function AddProducts(props: AddProductsProps) {
    const { openDialog, handleCloseDialog } = props
    const { updatePrice } = useAccessControl()

    const { sm } = useBreakPoints()

    const { merchantId } = useContext(AuthContext) as any

    // USESTATE & USEREF
    const [openPricing, setOpenPricing] = useState<boolean>(false)
    const ref = useRef<number>(0)

    // HOOK FORM
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<ProductForm>({ mode: "onSubmit" })

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // MUTATION
    const { isLoading, mutate, data } = useMutation((formData) =>
        axiosPrivate.post(`/merchants/${merchantId}/products`, formData)
    )

    // HELPERS and EVENT HANDLERS
    const onSubmit = (form: any) => {
        ref.current = 1
        mutate(form)
    }
    const continueAndAddPricing = (form: any) => {
        ref.current = 2
        mutate(form)
    }
    const handleClosePricingDialog = () => {
        setOpenPricing(false)
    }

    // USEEFFECT
    useEffect(() => {
        if (data?.data.ok && ref.current === 1) {
            handleCloseDialog()
        }
    }, [data?.data.ok])
    useEffect(() => {
        if (data?.data.ok && ref.current === 2) {
            setOpenPricing(true)
        }
    }, [data?.data.ok, ref.current])
    // RENDER
    if (openPricing)
        return (
            <AddPrices
                productId={data?.data?.data?.id}
                openDialog={openPricing}
                handleClosePricingDialog={handleClosePricingDialog}
                handleCloseProductDialog={handleCloseDialog}
            />
        )
    return (
        <Drawer
            open={openDialog}
            anchor="right"
            onClose={handleCloseDialog}
            PaperProps={{
                style: {
                    width: sm ? "50%" : "100%",
                },
            }}
        >
            <DialogTitle
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    bgcolor: "#fafafa",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "start",
                            mx: 2,
                        }}
                    >
                        {" "}
                        Add product
                    </Typography>
                    <Tooltip title="Close Dialog" arrow enterDelay={50}>
                        <IconButton
                            onClick={() => {
                                handleCloseDialog()
                            }}
                            sx={{ color: "#000" }}
                        >
                            <Close />
                        </IconButton>
                    </Tooltip>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        mx: 2,
                        mt: 1,
                    }}
                >
                    <Grid container columnSpacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography>Product Info</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: "Product name is required",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="Product Name"
                                        fullWidth
                                        margin="normal"
                                        autoComplete="name"
                                        error={!!errors?.name}
                                        helperText={
                                            errors?.name
                                                ? errors.name.message
                                                : ""
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 1.5,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="product_logo"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="Product Logo"
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        disabled
                                        autoComplete="product_logo"
                                        sx={{
                                            mt: 1.5,
                                            mb: 0.5,
                                            mr: 1,
                                            flex: 1,
                                        }}
                                        {...field}
                                        InputProps={{
                                            endAdornment: (
                                                <label htmlFor="product-logo-file-input">
                                                    <Button
                                                        sx={{
                                                            mr: -2,
                                                        }}
                                                        component="label"
                                                        disabled
                                                    >
                                                        <CloudUpload />
                                                        <input
                                                            id="product-logo-file-input"
                                                            type="file"
                                                            hidden
                                                            disabled
                                                        />
                                                    </Button>
                                                </label>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Controller
                                name="description"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        multiline
                                        label="Description"
                                        rows={6}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 0.5,
                                            mr: 1,
                                            flex: 1,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    mt: 1,
                    pr: 3,
                    py: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        ":hover": { bgcolor: "secondary.main" },
                        backgroundColor: "secondary.main",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                        p: 1,
                    }}
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    disabled={ref.current === 1 && isLoading}
                >
                    <Typography sx={{ color: "#fff" }}>Save & Close</Typography>
                </Button>
                {updatePrice && (
                    <Button
                        size="small"
                        variant="contained"
                        sx={{
                            bgcolor: "primary.main",
                            ":hover": { bgcolor: "primary.main" },
                            textTransform: "none",
                            fontWeight: "bold",
                            width: 120,
                            p: 1,
                        }}
                        type="submit"
                        onClick={handleSubmit(continueAndAddPricing)}
                        disabled={ref.current === 2 && isLoading}
                    >
                        <Typography sx={{ color: "secondary.main" }}>
                            Add Pricing
                        </Typography>
                        {isLoading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: "black",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                }}
                            />
                        )}
                    </Button>
                )}
            </DialogActions>
        </Drawer>
    )
}
export default AddProducts
