import { axiosPrivate } from "../utils/api/api"
import useAuth from "./useAuth"

const useRefreshToken = () => {
    const { setAuth }: any = useAuth()

    const refresh = async () => {
        const response = await axiosPrivate.post("/auth/token", {
            refresh_token: window.localStorage.getItem("refresh token"),
            grant_type: "refresh_token",
        })

        if (response) {
            window.localStorage.setItem(
                "refresh token",
                response?.data?.data?.refresh_token
            )
        }

        setAuth((prev: any) => {
            // eslint-disable-next-line no-param-reassign
            prev = response.data.data
            return prev
        })

        return response.data.data.access_token
    }
    return refresh
}

export default useRefreshToken
