import { useQuery } from "@tanstack/react-query"
import { useSnackbar } from "notistack"
import useAxiosPrivate from "./useAxiosPrivate"

type FetchEntities = {
    endPoint: string
    sortModel?: {
        field: any
        sort: string
    }[]
    filterModel?:
        | {
              column_field: string
              operator_value: any
              value: any
          }[]
        | null
    searchText?: string
    linkOperator?: string
}

// Fetch/Get All Entities List Hook with Filter, Search and Sort Preferences
const useFetchEntities = (props: FetchEntities, options?: any) => {
    const { endPoint, filterModel, sortModel, searchText } = props
    const { enqueueSnackbar } = useSnackbar()
    const axiosPrivate = useAxiosPrivate()
    return useQuery({
        queryKey: [endPoint],
        queryFn: () =>
            axiosPrivate
                .get(`${endPoint}`, {
                    params: {
                        per_page: -1,
                        sort:
                            sortModel?.length === 0
                                ? undefined
                                : JSON.stringify(sortModel),
                        search: searchText,
                        filter:
                            filterModel?.length === 0
                                ? []
                                : JSON.stringify(filterModel),
                        link_operator: "and",
                    },
                })
                .then((response) => response.data),

        onError: (error: any) => {
            const fieldError =
                error.response?.data?.error?.field_error?.[0]?.description
            const errorMessage = error.response?.data?.error?.message
            enqueueSnackbar(fieldError || errorMessage || "Request Failed", {
                variant: "error",
            })
        },
        ...options,
    })
}
export default useFetchEntities
