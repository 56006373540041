import { useParams } from "react-router-dom"
import {
    Avatar,
    Box,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material"
import { HighlightOff, TaskAlt } from "@mui/icons-material"

import {
    SSO_API_BASE_URL,
    SSO_ASSETS_URL,
    SSO_VERSION,
} from "../../utils/config"
import useAccessControl from "../../hooks/useAccessControl"
import useFetchEntitiesPerPage from "../../hooks/useFetchEntitiesPerPage"
import ErrorPage, { ErrorResponse } from "../../component/errorPage"

// System User Details View Component
function UserDetails() {
    const { userPhone } = useParams()
    const { systemUsersList } = useAccessControl()

    const { data: user, isLoading } = useFetchEntitiesPerPage(
        {
            endPoint: "users",
            perPage: -1,
            filterModel: [
                {
                    column_field: "phone",
                    operator_value: "contains",
                    value: `${userPhone}`,
                },
            ],
        },
        {
            enabled: systemUsersList,
        }
    ) as any

    // If a user is not present
    if (user?.data?.length === 0) {
        const err: ErrorResponse = {
            error: {
                code: 404,
                description: "User not found.",
                message: "User is not found.",
            },
        }
        return <ErrorPage error={err.error} />
    }
    return isLoading ? (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                m: 2,
            }}
        >
            <CircularProgress size={40} sx={{ color: "black" }} />
        </Box>
    ) : (
        <Grid
            container
            sx={{
                bgcolor: "#fff",
                justifyContent: "cenetr",
            }}
        >
            <Grid item sm={12} xs={12} sx={{ mt: 2 }}>
                <Typography
                    sx={{
                        fontWeight: "bold",
                        fontSize: 20,
                        ml: { xs: 0, sm: 2 },
                    }}
                >
                    Profile
                </Typography>
                <Grid item>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            my: "1em",
                        }}
                    >
                        <Avatar
                            sx={{
                                width: { sm: 100, xs: 60 },
                                height: { sm: 100, xs: 60 },
                            }}
                            src={`${SSO_API_BASE_URL}/${SSO_VERSION}/${SSO_ASSETS_URL}/${user?.data[0]?.profile_picture}`}
                        />
                    </Box>
                </Grid>
                <Grid container>
                    <Grid
                        item
                        md={5.5}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                First Name
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={user?.data[0]?.first_name}
                                disabled
                                multiline
                                fullWidth
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.5}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                Middle Name
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={user?.data[0]?.middle_name}
                                disabled
                                multiline
                                fullWidth
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.5}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                Last Name
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={user?.data[0]?.last_name}
                                disabled
                                multiline
                                fullWidth
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.5}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                Phone
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={user?.data[0]?.phone}
                                disabled
                                multiline
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.5}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                Email
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={user?.data[0]?.email}
                                disabled
                                multiline
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.5}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                Role(s)
                            </Typography>
                            <Divider />
                            <Box
                                sx={{
                                    mt: "0.5em",
                                }}
                            >
                                {user?.data[0]?.roles?.map((role: any) => (
                                    <Box
                                        key={role.id}
                                        sx={{
                                            display: "inline-block",
                                            justifyContent: "start",
                                            alignItems: "flex-start",
                                            fontWeight: "bold",
                                            overflow: "hidden",
                                            m: ".1em",
                                            backgroundColor:
                                                role?.status === "ACTIVE"
                                                    ? "success.main"
                                                    : "error.main",
                                            color: "white",
                                            borderRadius: "20px",
                                            pr: "1em",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <IconButton
                                                size="small"
                                                sx={{
                                                    color: "white",
                                                }}
                                            >
                                                {role?.status === "ACTIVE" ? (
                                                    <TaskAlt
                                                        sx={{
                                                            fontSize: "1em",
                                                        }}
                                                    />
                                                ) : (
                                                    <HighlightOff
                                                        sx={{
                                                            fontSize: "1em",
                                                        }}
                                                    />
                                                )}
                                            </IconButton>
                                            <Typography
                                                variant="subtitle2"
                                                noWrap
                                            >
                                                {role?.role_name}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default UserDetails
