export const BASE_URL = process.env.REACT_APP_BASE_URL
export const MERCHANT_ADMIN_BASE_URL =
    process.env.REACT_APP_MERCHANT_ADMIN_BASE_URL
export const SSO_BASE_URL = process.env.REACT_APP_SSO_BASE_URL
export const SSO_API_BASE_URL = process.env.REACT_APP_SSO_API_BASE_URL
export const SSO_CLIENT_ID = process.env.REACT_APP_SSO_CLIENT_ID
export const SSO_ASSETS_URL = "assets/profile_picture"
export const MERCHANT_ASSETS_URL = "assets"
export const SSO_VERSION = "v1"
export const VERSION = "v2"
export const FREQUENCIES = ["YEAR", "MONTH", "WEEK", "DAY"]
