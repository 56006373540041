import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

function Listener() {
    const navigate = useNavigate()
    const listener = (e: { data: string }) => {
        if (typeof e.data !== "string") return
        if (e.data === "login-success") {
            JSON.parse(window.localStorage.getItem("registered") ?? "true")
                ? navigate("/")
                : navigate("/register")
        } else if (e.data === "login-fail") navigate("/login")
    }
    useEffect(
        () => () => {
            window.removeEventListener("message", listener)
        },
        []
    )
    window.addEventListener("message", listener)
    return <div />
}

export default Listener
