import { useContext, useEffect, useMemo, useRef, useState } from "react"
import {
    DialogActions,
    Button,
    DialogContent,
    CircularProgress,
    Typography,
    DialogTitle,
    Box,
    Grid,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Avatar,
    Drawer,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { Controller, useForm } from "react-hook-form"
import { useMutation } from "@tanstack/react-query"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon"
import { DesktopDateTimePicker } from "@mui/x-date-pickers"
import { useSnackbar } from "notistack"
import { DateTime } from "luxon"

import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import useFetchEntities from "../../hooks/useFetchEntities"
import useFetchEntitiesPerPage from "../../hooks/useFetchEntitiesPerPage"
import useBreakPoints from "../../hooks/useBreakPoints"
import AuthContext from "../../context/authProvider"

interface UpdateDiscountProps {
    update: boolean
    discountData: any
    handleUpdateModalClose: () => void
    refetch: () => void
}
interface DiscountForm {
    discount_code: string
    amount: number
    currency_id: string
    discount_type: string
    duration: string
    effective_from: string
    effective_to: string
    max_budget: number
    max_redemption: number
    max_redemption_per_user: number
    number_of_cycles: number
    payment_method_ids: string[]
    price_ids: string[]
    products: string[]
}

function UpdateDiscount(props: UpdateDiscountProps) {
    const { update, discountData, handleUpdateModalClose, refetch } = props
    const { discountCode } = useParams() as any

    const { merchantId } = useContext(AuthContext) as any

    const [durationType, setDurationType] = useState<string>(
        discountData?.duration
    )

    const productsRef = useRef<string[]>([])

    const { sm } = useBreakPoints()

    // HOOK FORM
    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
        register,
    } = useForm<DiscountForm>({
        mode: "onChange",
        defaultValues: {
            ...discountData,
            discount_code: discountCode,
            duration: discountData?.duration,
            price_ids: discountData?.price_ids,
            effective_from: DateTime.fromISO(discountData?.effective_from),
            effective_to: DateTime.fromISO(discountData?.effective_to),
        },
    })

    productsRef.current = watch("products")

    const axiosPrivate = useAxiosPrivate()

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // QUERY
    const { isLoading: banksDataLoading, data: banks } = useFetchEntities({
        endPoint: "/payment-methods",
    }) as any
    const { isLoading: loading, data: currencies } = useFetchEntities({
        endPoint: "/currencies",
    }) as any
    const { isLoading: loadingProducts, data: products } =
        useFetchEntitiesPerPage({
            endPoint: "products",
            perPage: -1,
        }) as any

    // MUTATION
    const { isLoading, mutate, isSuccess } = useMutation(
        (formData: any) =>
            axiosPrivate.patch(
                `/merchants/${merchantId}/discounts/${formData?.id}`,
                formData
            ),
        {
            onSuccess: () => {
                enqueueSnackbar("Successfully updated!", {
                    variant: "success",
                })
            },
            onError: (error: any) => {
                const fieldError =
                    error.response?.data?.error?.field_error?.[0]?.description
                const errorMessage = error.response?.data?.error?.message
                enqueueSnackbar(
                    fieldError || errorMessage || "Request Failed",
                    {
                        variant: "error",
                    }
                )
            },
        }
    )

    // HELPERS and EVENT HANDLERS
    const onSubmit = (form: any) => {
        delete form?.products
        delete form?.code

        mutate(form)
    }
    // USEEFFECT
    useEffect(() => {
        if (isSuccess) {
            refetch()
            handleUpdateModalClose()
        }
    }, [isSuccess])
    const productData = useMemo(
        () =>
            products?.data
                ?.filter((item: any) =>
                    item?.prices?.some((price: any) =>
                        discountData?.price_ids?.includes(price?.id)
                    )
                )
                ?.map((product: any) => product?.id),
        [discountData?.price_ids, products?.data]
    )
    const prices = useMemo(
        () =>
            products?.data
                ?.filter((element: { id: string }) =>
                    productsRef.current?.includes(element.id)
                )
                ?.map((item: { prices: any }) => item.prices)
                ?.flatMap((price: any) => price),
        [productsRef.current, products?.data]
    )

    // RENDER
    return (
        <Drawer
            open={update}
            anchor="right"
            onClose={handleUpdateModalClose}
            PaperProps={{
                style: {
                    width: sm ? "50%" : "100%",
                },
            }}
        >
            <DialogTitle
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    bgcolor: "#fafafa",
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        {" "}
                        Update Discount
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                {loading || banksDataLoading || loadingProducts ? (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress
                            size={30}
                            sx={{
                                color: "black",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                            }}
                        />
                    </Box>
                ) : (
                    <Box
                        sx={{
                            mx: 2,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Controller
                            name="discount_code"
                            control={control}
                            rules={{
                                required: "Discount code can't be empty.",
                            }}
                            render={({ field }) => (
                                <TextField
                                    id="discount_code"
                                    variant="outlined"
                                    label="Code"
                                    margin="normal"
                                    fullWidth
                                    disabled
                                    error={!!errors?.discount_code}
                                    helperText={
                                        errors?.discount_code
                                            ? errors?.discount_code?.message
                                            : null
                                    }
                                    size="small"
                                    sx={{
                                        mt: 1.5,
                                        mb: 0.5,
                                        mr: 1,
                                        flex: 1,
                                        my: 2,
                                    }}
                                    {...field}
                                />
                            )}
                        />
                        <Grid container columnSpacing={3} sx={{ my: 1 }}>
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    size="small"
                                    fullWidth
                                    sx={{
                                        mt: "0.7em",
                                    }}
                                >
                                    <InputLabel id="payment_method_ids">
                                        Payment Method(s)
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        {...register(`payment_method_ids`)}
                                        defaultValue={
                                            discountData?.payment_method_ids
                                                ? discountData?.payment_method_ids
                                                : []
                                        }
                                        labelId="payment_method_ids"
                                        label="Payment Method(s)"
                                        multiple
                                        MenuProps={{
                                            sx: {
                                                "&& .Mui-selected": {
                                                    color: "secondary.main",
                                                },
                                            },
                                        }}
                                    >
                                        {banks?.data?.map(
                                            (item: {
                                                id: string
                                                logo: string
                                                name: string
                                            }) => (
                                                <MenuItem
                                                    value={item?.id}
                                                    key={item?.id}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <Avatar
                                                            sx={{
                                                                width: 30,
                                                                height: 30,
                                                                mr: 1,
                                                            }}
                                                            src={item?.logo}
                                                        />
                                                        {item.name.replace(
                                                            "_",
                                                            " "
                                                        )}
                                                    </Box>
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    size="small"
                                    fullWidth
                                    sx={{
                                        mt: "0.7em",
                                    }}
                                >
                                    <InputLabel id="products">
                                        Product(s)
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        defaultValue={
                                            productData ? productData : []
                                        }
                                        labelId="products"
                                        label="Product(s)"
                                        multiple
                                        MenuProps={{
                                            sx: {
                                                "&& .Mui-selected": {
                                                    backgroundColor:
                                                        "primary.main",
                                                    color: "secondary.main",
                                                },
                                            },
                                        }}
                                        {...register(`products`)}
                                    >
                                        {products?.data?.map(
                                            (item: {
                                                id: string
                                                name: string
                                            }) => (
                                                <MenuItem
                                                    value={item?.id}
                                                    key={item?.id}
                                                >
                                                    {item.name}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    size="small"
                                    fullWidth
                                    sx={{
                                        mt: "0.7em",
                                    }}
                                >
                                    <InputLabel id="price_ids">
                                        Subscription Plan(s)
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        defaultValue={
                                            discountData?.price_ids
                                                ? discountData?.price_ids
                                                : []
                                        }
                                        labelId="price_ids"
                                        label="Subscription Plan(s)"
                                        multiple
                                        MenuProps={{
                                            sx: {
                                                "&& .Mui-selected": {
                                                    backgroundColor:
                                                        "primary.main",
                                                    color: "secondary.main",
                                                },
                                            },
                                        }}
                                        {...register(`price_ids`)}
                                    >
                                        {prices?.map(
                                            (item: {
                                                id: string
                                                name: string
                                            }) => (
                                                <MenuItem
                                                    value={item?.id}
                                                    key={item?.id}
                                                >
                                                    {item.name ??
                                                        "Name unknown"}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="max_budget"
                                    control={control}
                                    rules={{
                                        required: watch(`max_redemption`)
                                            ? false
                                            : "Max budget can't be empty.",
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            id="max_budget"
                                            variant="outlined"
                                            label="Max Budget"
                                            margin="normal"
                                            fullWidth
                                            type="number"
                                            disabled={!!watch(`max_redemption`)}
                                            error={!!errors?.max_budget}
                                            helperText={
                                                errors?.max_budget
                                                    ? errors?.max_budget
                                                          ?.message
                                                    : null
                                            }
                                            size="small"
                                            sx={{
                                                mt: 1.5,
                                                mb: 0.5,
                                                mr: 1,
                                                flex: 1,
                                            }}
                                            {...field}
                                            onChange={(e) =>
                                                field.onChange(
                                                    parseInt(e.target.value)
                                                )
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="max_redemption"
                                    control={control}
                                    rules={{
                                        required: watch(`max_budget`)
                                            ? false
                                            : "Max number of customers can't be empty.",
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            id="max_redemption"
                                            variant="outlined"
                                            label="Max No. of Customers"
                                            margin="normal"
                                            fullWidth
                                            type="number"
                                            disabled={!!watch(`max_budget`)}
                                            error={!!errors?.max_redemption}
                                            helperText={
                                                errors?.max_redemption
                                                    ? errors?.max_redemption
                                                          ?.message
                                                    : null
                                            }
                                            size="small"
                                            sx={{
                                                mt: 1.5,
                                                mb: 0.5,
                                                mr: 1,
                                                flex: 1,
                                            }}
                                            {...field}
                                            onChange={(e) =>
                                                field.onChange(
                                                    parseInt(e.target.value)
                                                )
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="max_redemption_per_user"
                                    control={control}
                                    rules={{
                                        required:
                                            "Max redemption per user can't be empty.",
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            id="max_redemption_per_user"
                                            variant="outlined"
                                            label="Max Redemption Per User"
                                            margin="normal"
                                            fullWidth
                                            type="number"
                                            error={
                                                !!errors?.max_redemption_per_user
                                            }
                                            helperText={
                                                errors?.max_redemption_per_user
                                                    ? errors
                                                          ?.max_redemption_per_user
                                                          ?.message
                                                    : null
                                            }
                                            size="small"
                                            sx={{
                                                mt: 1.5,
                                                mb: 0.5,
                                                mr: 1,
                                                flex: 1,
                                            }}
                                            {...field}
                                            onChange={(e) =>
                                                field.onChange(
                                                    parseInt(e.target.value)
                                                )
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={durationType === "REPEATING" ? 6 : 12}
                                sm={durationType === "REPEATING" ? 3 : 6}
                            >
                                <FormControl
                                    size="small"
                                    fullWidth
                                    sx={{
                                        mt: "0.7em",
                                    }}
                                >
                                    <InputLabel id="duration">
                                        Duration
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        {...register(`duration`, {
                                            onChange: (event: {
                                                target: {
                                                    value: string
                                                }
                                            }) =>
                                                setDurationType(
                                                    event.target.value
                                                ),
                                        })}
                                        value={durationType}
                                        labelId="duration"
                                        label="Duration"
                                        MenuProps={{
                                            sx: {
                                                "&& .Mui-selected": {
                                                    backgroundColor:
                                                        "primary.main",
                                                    color: "secondary.main",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="ONCE">Once</MenuItem>
                                        <MenuItem value="REPEATING">
                                            Repeating
                                        </MenuItem>
                                        <MenuItem value="FOREVER">
                                            Forever
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {durationType === "REPEATING" && (
                                <Grid item xs={6} sm={3}>
                                    <Controller
                                        name="number_of_cycles"
                                        control={control}
                                        rules={{
                                            required:
                                                durationType === "REPEATING"
                                                    ? "No. of cycles can't be empty."
                                                    : false,
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                id="number_of_cycles"
                                                variant="outlined"
                                                label="Number of Cycles"
                                                margin="normal"
                                                fullWidth
                                                type="number"
                                                error={
                                                    !!errors?.number_of_cycles
                                                }
                                                helperText={
                                                    errors?.number_of_cycles
                                                        ? errors
                                                              ?.number_of_cycles
                                                              ?.message
                                                        : null
                                                }
                                                size="small"
                                                sx={{
                                                    mt: 1.5,
                                                    mb: 0.5,
                                                    mr: 1,
                                                    flex: 1,
                                                }}
                                                {...field}
                                                onChange={(e) =>
                                                    field.onChange(
                                                        parseInt(e.target.value)
                                                    )
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="effective_from"
                                    control={control}
                                    rules={{
                                        required: "Start date can't be empty.",
                                    }}
                                    render={({ field }) => (
                                        <LocalizationProvider
                                            dateAdapter={AdapterLuxon}
                                        >
                                            <DesktopDateTimePicker
                                                label="Start Date"
                                                disablePast
                                                {...field}
                                                slots={{
                                                    textField: (params) => (
                                                        <TextField
                                                            id="effective_from"
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            {...params}
                                                            InputProps={
                                                                params.InputProps
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            error={
                                                                !!errors?.effective_from
                                                            }
                                                            helperText={
                                                                errors?.effective_from
                                                                    ? errors
                                                                          ?.effective_from
                                                                          ?.message
                                                                    : null
                                                            }
                                                            size="small"
                                                            sx={{
                                                                mt: 1.5,
                                                                mb: 0.5,
                                                                mr: 1,
                                                                flex: 1,
                                                            }}
                                                            onChange={(event: {
                                                                target: {
                                                                    value: any
                                                                }
                                                            }) =>
                                                                field.onChange(
                                                                    DateTime.fromISO(
                                                                        event
                                                                            ?.target
                                                                            ?.value
                                                                    )
                                                                        .toUTC()
                                                                        .toFormat(
                                                                            "yyyy-MM-dd'T'HH:mm:ss'Z'"
                                                                        )
                                                                )
                                                            }
                                                        />
                                                    ),
                                                }}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="effective_to"
                                    control={control}
                                    rules={{
                                        required: "End date can't be empty.",
                                    }}
                                    render={({ field }) => (
                                        <LocalizationProvider
                                            dateAdapter={AdapterLuxon}
                                        >
                                            <DesktopDateTimePicker
                                                label="End Date"
                                                disablePast
                                                {...field}
                                                value={DateTime.fromISO(
                                                    discountData?.effective_to
                                                )}
                                                slots={{
                                                    textField: (params) => (
                                                        <TextField
                                                            id="effective_to"
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            {...params}
                                                            InputProps={
                                                                params.InputProps
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            error={
                                                                !!errors?.effective_to
                                                            }
                                                            helperText={
                                                                errors?.effective_to
                                                                    ? errors
                                                                          ?.effective_to
                                                                          ?.message
                                                                    : null
                                                            }
                                                            size="small"
                                                            sx={{
                                                                mt: 1.5,
                                                                mb: 0.5,
                                                                mr: 1,
                                                                flex: 1,
                                                            }}
                                                            onChange={(event: {
                                                                target: {
                                                                    value: any
                                                                }
                                                            }) =>
                                                                field.onChange(
                                                                    DateTime.fromISO(
                                                                        event
                                                                            ?.target
                                                                            ?.value
                                                                    )
                                                                        .toUTC()
                                                                        .toFormat(
                                                                            "yyyy-MM-dd'T'HH:mm:ss'Z'"
                                                                        )
                                                                )
                                                            }
                                                        />
                                                    ),
                                                }}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="discount_type"
                                    control={control}
                                    rules={{
                                        required:
                                            "Discount type can't be empty.",
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            id="discount_type"
                                            variant="outlined"
                                            label="Discount Type"
                                            margin="normal"
                                            fullWidth
                                            select
                                            error={!!errors?.discount_type}
                                            helperText={
                                                errors?.discount_type
                                                    ? errors?.discount_type
                                                          ?.message
                                                    : null
                                            }
                                            size="small"
                                            sx={{
                                                mt: 1.5,
                                                mb: 0.5,
                                                mr: 1,
                                                flex: 1,
                                            }}
                                            SelectProps={{
                                                MenuProps: {
                                                    sx: {
                                                        "& .Mui-selected": {
                                                            backgroundColor: (
                                                                theme
                                                            ) =>
                                                                theme.palette
                                                                    .primary
                                                                    .main,
                                                            color: (theme) =>
                                                                theme.palette
                                                                    .secondary
                                                                    .main,
                                                        },
                                                    },
                                                },
                                            }}
                                            {...field}
                                        >
                                            <MenuItem value="FLAT">
                                                Number
                                            </MenuItem>
                                            <MenuItem value="PERCENT">
                                                Percent
                                            </MenuItem>
                                        </TextField>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Controller
                                    name="amount"
                                    control={control}
                                    rules={{
                                        required:
                                            "Amount/Percent can't be empty.",
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            id="amount"
                                            variant="outlined"
                                            label="Amount/Percent"
                                            margin="normal"
                                            type="number"
                                            fullWidth
                                            error={!!errors?.amount}
                                            helperText={
                                                errors?.amount
                                                    ? errors?.amount?.message
                                                    : null
                                            }
                                            size="small"
                                            sx={{
                                                mt: 1.5,
                                                mb: 0.5,
                                                mr: -2,
                                                flex: 2,
                                            }}
                                            {...field}
                                            onChange={(e) =>
                                                field.onChange(
                                                    parseInt(e.target.value)
                                                )
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Controller
                                    name="currency_id"
                                    control={control}
                                    rules={{
                                        required: "Currency can't be empty.",
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            id="currency_id"
                                            variant="outlined"
                                            label="Currency"
                                            margin="normal"
                                            fullWidth
                                            select
                                            error={!!errors?.currency_id}
                                            helperText={
                                                errors?.currency_id
                                                    ? errors?.currency_id
                                                          ?.message
                                                    : null
                                            }
                                            size="small"
                                            sx={{
                                                mt: 1.5,
                                                mb: 0.5,
                                                mr: 1,
                                                flex: 1,
                                            }}
                                            SelectProps={{
                                                MenuProps: {
                                                    sx: {
                                                        "& .Mui-selected": {
                                                            backgroundColor: (
                                                                theme
                                                            ) =>
                                                                theme.palette
                                                                    .primary
                                                                    .main,
                                                            color: (theme) =>
                                                                theme.palette
                                                                    .secondary
                                                                    .main,
                                                        },
                                                    },
                                                },
                                            }}
                                            {...field}
                                        >
                                            {currencies?.data?.map(
                                                (item: any) => (
                                                    <MenuItem
                                                        value={item?.id}
                                                        key={item?.id}
                                                    >
                                                        {item.code}
                                                    </MenuItem>
                                                )
                                            )}
                                        </TextField>
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </DialogContent>
            <DialogActions
                sx={{
                    mt: 1,
                    pr: 3,
                    py: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        ":hover": { bgcolor: "#e4e4e4" },
                        backgroundColor: "#e4e4e4",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                        p: 1,
                    }}
                    onClick={handleUpdateModalClose}
                >
                    <Typography sx={{ color: "primary.main" }}>
                        Cancel
                    </Typography>
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        bgcolor: "primary.main",
                        ":hover": {
                            bgcolor: "primary.main",
                        },
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                        p: 1,
                    }}
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    disabled={isLoading || loading}
                >
                    <Typography sx={{ color: "secondary.main" }}>
                        Update
                    </Typography>
                    {isLoading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: "black",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                            }}
                        />
                    )}
                </Button>
            </DialogActions>
        </Drawer>
    )
}
export default UpdateDiscount
