import { Box } from "@mui/material"

import useBreakPoints from "../hooks/useBreakPoints"

function Unauthorized() {
    const { xs, md } = useBreakPoints()
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                color: "secondary.main",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".5em",
                }}
            >
                <img
                    style={{
                        width: "100%",
                        height: xs && !md ? "300px" : "600px",
                    }}
                    src={`${process.env.PUBLIC_URL}/assets/error_401.jpg`}
                    alt="access denied"
                />
            </Box>
        </Box>
    )
}

export default Unauthorized
