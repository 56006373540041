import { Alert, AlertColor, AlertTitle } from "@mui/material"
import { ReactNode } from "react"

const severity = (status: string): AlertColor => {
    switch (status) {
        case "ACTIVE":
            return "success"
        case "SUCCEEDED":
            return "success"
        case "INACTIVE":
            return "error"
        case "FAILED":
            return "error"
        case "EXPIRED":
            return "error"
        case "CANCELED":
            return "warning"
        default:
            return "error"
    }
}

interface CustomAlertProps {
    status: string
    variant: "filled" | "outlined" | "standard"
    message?: ReactNode
}

function CustomAlert({ status, variant, message }: CustomAlertProps) {
    return (
        <Alert
            severity={severity(status)}
            sx={{ bgcolor: "inherit", mt: "0.5em", pl: "0.5em" }}
            variant={variant ?? "standard"}
        >
            <AlertTitle sx={{ mt: 0 }}>{status}</AlertTitle>
            {message}
        </Alert>
    )
}
export default CustomAlert
