import { useContext, useEffect, useRef, useState } from "react"
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material"
import { useSnackbar } from "notistack"
import { Controller, useForm } from "react-hook-form"
import { useMutation, useQuery } from "@tanstack/react-query"
import { Search } from "@mui/icons-material"

import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import AuthContext from "../../context/authProvider"
import useAccessControl from "../../hooks/useAccessControl"

interface AddUsersProps {
    handleModalClose: () => void
    refetch: () => void
    open: boolean
}
interface AddUsersForm {
    role: string
}
// Add Users Component: This component is intended to assigns roles for system users.
function AddUsers({ handleModalClose, refetch, open }: AddUsersProps) {
    const { rolesList, assignRole } = useAccessControl()
    const { merchantId } = useContext(AuthContext) as any
    const [pageStatus, setPageStatus] = useState<string>("SEARCHING")
    const [searchText, setSearchText] = useState<string>()
    const userId = useRef({})

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // HOOK FORM
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<AddUsersForm>({
        mode: "onChange",
    })

    // MUTATION & QUERY
    const { mutate, data, error, isLoading } = useMutation((formData) =>
        axiosPrivate.post(
            `/merchants/${merchantId}/users/${userId.current}/roles`,
            formData
        )
    ) as any
    const {
        isFetching: searchLoading,
        data: searchData,
        error: searchError,
        refetch: searchRefetch,
    } = useQuery(
        ["find_user", searchText],
        () =>
            axiosPrivate.get(
                `/merchants/${merchantId}/users/phone/${searchText}`,
                {
                    params: {
                        per_page: -1,
                    },
                }
            ),
        {
            refetchOnWindowFocus: false,
            retry: false,
            enabled: false,
        }
    ) as any
    const { data: roles } = useQuery(
        ["get_system_roles"],
        () =>
            axiosPrivate.get(`/merchants/${merchantId}/roles`, {
                params: {
                    per_page: -1,
                },
            }),
        {
            enabled: rolesList,
        }
    ) as any
    // EVENT HANDLERS & HELPERS
    const onSubmit = (formData: any) => {
        mutate(formData)
    }
    const handleChange = (event: { target: { value: string } }) => {
        if (event.target.value?.length === 10) {
            setSearchText(event.target.value)
            setTimeout(() => searchRefetch(), 100)
        } else if (event.target.value?.length < 10) {
            setSearchText(event.target.value)
            setPageStatus("INPUT_LENGTH_DECREASED")
        }
        return event.target.value
    }

    // USEFFECTS
    useEffect(() => {
        if (searchError) {
            setPageStatus("NOT_FOUND")
        }
    }, [searchError])
    useEffect(() => {
        if (searchData) {
            if (searchData?.data?.data?.phone) {
                setPageStatus("USER_FOUND")
                userId.current = searchData?.data?.data?.id
            }
        }
    }, [searchData])
    useEffect(() => {
        if (data) {
            enqueueSnackbar(`Succesfully added a user!`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000,
            })
            refetch()
            handleModalClose()
        }
    }, [data, enqueueSnackbar, handleModalClose, refetch])
    useEffect(() => {
        if (error) {
            if (error?.response?.data?.error?.field_error?.length > 0) {
                error?.response?.data?.error?.field_error?.map((msg: any) =>
                    enqueueSnackbar(msg.description || "Network Error!", {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    })
                )
            } else {
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                )
            }
        }
    }, [enqueueSnackbar, error])

    // RENDER
    return (
        <Dialog
            fullWidth
            maxWidth={
                pageStatus !== "INPUT_LENGTH_DECREASED" &&
                (pageStatus === "USER_FOUND" || pageStatus === "ADD_USER") &&
                !searchLoading
                    ? "md"
                    : "xs"
            }
            scroll="paper"
            open={open}
            onClose={handleModalClose}
        >
            <DialogTitle
                sx={{
                    backgroundColor: "#FAFAFA",
                    py: 3,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        Add New User
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                {searchLoading ? (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            m: 2,
                        }}
                    >
                        <CircularProgress size={40} sx={{ color: "black" }} />
                    </Box>
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            flex: 1,
                            m: 2,
                        }}
                    >
                        {(pageStatus === "SEARCHING" ||
                            pageStatus === "NOT_FOUND" ||
                            pageStatus === "INPUT_LENGTH_DECREASED") && (
                            <TextField
                                variant="outlined"
                                autoFocus
                                size="small"
                                error={pageStatus === "NOT_FOUND"}
                                value={searchText}
                                onChange={(event) => handleChange(event)}
                                label="Search by Phone number"
                                placeholder="0911223344"
                                disabled={searchLoading}
                                InputProps={{
                                    disableUnderline: true,
                                    type: "number",
                                    endAdornment: (
                                        <IconButton
                                            title="Search"
                                            aria-label="Search"
                                        >
                                            <Search color="primary" />
                                        </IconButton>
                                    ),
                                }}
                            />
                        )}
                        {pageStatus === "NOT_FOUND" && (
                            <Box
                                sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Divider sx={{ my: 2 }} />
                                <Typography
                                    gutterBottom
                                    sx={{
                                        textAlign: "center",
                                        fontSize: 14,
                                        fontWeight: "bold",
                                    }}
                                >
                                    User is not found on the system!
                                </Typography>
                                <Divider sx={{ mt: 2, mb: -2 }} />
                            </Box>
                        )}
                        {pageStatus !== "INPUT_LENGTH_DECREASED" &&
                            (pageStatus === "USER_FOUND" ||
                                pageStatus === "ADD_USER") && (
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            autoFocus
                                            size="small"
                                            value={searchText}
                                            onChange={(event) =>
                                                handleChange(event)
                                            }
                                            label="Search by Phone number"
                                            placeholder="0911223344"
                                            disabled={searchLoading}
                                            InputProps={{
                                                disableUnderline: true,
                                                type: "number",
                                                endAdornment: (
                                                    <IconButton
                                                        title="Search"
                                                        aria-label="Search"
                                                    >
                                                        <Search color="primary" />
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={6} />
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            margin="normal"
                                            disabled
                                            value={
                                                searchText?.length === 10
                                                    ? searchData?.data?.data
                                                          ?.first_name
                                                    : ""
                                            }
                                            fullWidth
                                            size="small"
                                            label="First name"
                                            InputLabelProps={{
                                                shrink:
                                                    searchText?.length === 10 &&
                                                    true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            margin="normal"
                                            disabled
                                            value={
                                                searchText?.length === 10
                                                    ? searchData?.data?.data
                                                          ?.middle_name
                                                    : ""
                                            }
                                            fullWidth
                                            size="small"
                                            label="Middle name"
                                            InputLabelProps={{
                                                shrink:
                                                    searchText?.length === 10 &&
                                                    true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            margin="normal"
                                            disabled
                                            value={
                                                searchText?.length === 10
                                                    ? searchData?.data?.data
                                                          ?.last_name
                                                    : ""
                                            }
                                            fullWidth
                                            size="small"
                                            label="Last name"
                                            InputLabelProps={{
                                                shrink:
                                                    searchText?.length === 10 &&
                                                    true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            margin="normal"
                                            disabled
                                            value={
                                                searchText?.length === 10
                                                    ? searchData?.data?.data
                                                          ?.email
                                                    : ""
                                            }
                                            fullWidth
                                            size="small"
                                            label="Email"
                                            InputLabelProps={{
                                                shrink:
                                                    searchText?.length === 10 &&
                                                    true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            margin="normal"
                                            disabled
                                            value={
                                                searchText?.length === 10
                                                    ? searchData?.data?.data
                                                          ?.phone
                                                    : ""
                                            }
                                            fullWidth
                                            size="small"
                                            type="number"
                                            label="Phone number"
                                            InputLabelProps={{
                                                shrink:
                                                    searchText?.length === 10 &&
                                                    true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            margin="normal"
                                            disabled
                                            value={
                                                searchText?.length === 10
                                                    ? searchData?.data?.data
                                                          ?.gender
                                                    : ""
                                            }
                                            fullWidth
                                            size="small"
                                            label="Gender"
                                            InputLabelProps={{
                                                shrink:
                                                    searchText?.length === 10 &&
                                                    true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="role"
                                            control={control}
                                            rules={{
                                                required: "Role is required",
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    margin="normal"
                                                    select
                                                    fullWidth
                                                    size="small"
                                                    label="Role"
                                                    helperText={
                                                        errors?.role
                                                            ? errors?.role
                                                                  ?.message
                                                            : ""
                                                    }
                                                    error={!!errors?.role}
                                                    {...field}
                                                >
                                                    {roles?.data?.data?.map(
                                                        (item: {
                                                            id: string
                                                            name: string
                                                        }) => (
                                                            <MenuItem
                                                                sx={{
                                                                    zIndex: (
                                                                        theme
                                                                    ) =>
                                                                        theme
                                                                            .zIndex
                                                                            .drawer +
                                                                        3,
                                                                }}
                                                                key={item?.id}
                                                                value={item?.id}
                                                            >
                                                                {item?.name}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </TextField>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                    </Box>
                )}
            </DialogContent>
            <DialogActions
                sx={{
                    mb: 1,
                    pr: 4,
                    pt: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        ":hover": { bgcolor: "#e4e4e4" },
                        backgroundColor: "#e4e4e4",
                        color: "primary.main",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    onClick={handleModalClose}
                >
                    <Typography>Cancel</Typography>
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        bgcolor: "primary.main",
                        ":hover": { bgcolor: "primary.main" },
                        color: "secondary.main",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    disabled={
                        searchText?.length !== 10 ||
                        pageStatus === "NOT_FOUND" ||
                        searchLoading ||
                        isLoading ||
                        !assignRole
                    }
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                >
                    <Typography>Add</Typography>
                    {isLoading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: "#0a0a0a",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                            }}
                        />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default AddUsers
