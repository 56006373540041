import { Box, CircularProgress, Typography } from "@mui/material"

function Loading({ message }: { message: string }) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Typography variant="body2">{message}</Typography>
            <CircularProgress />
        </Box>
    )
}

export default Loading
