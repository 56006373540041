import { ColumnFiltersState } from "@tanstack/react-table"
import { find } from "lodash"

const filterModel = (
    columnFilterFns: any,
    columnFilters: ColumnFiltersState | null,
    columns?: any[]
) =>
    columnFilters &&
    columnFilters.map((item: { id: string; value: any }) => {
        const operatorValue = columnFilterFns[item.id]
        const colType = find(
            columns,
            (el) => el.accessorKey === item.id || el.id === item.id
        )?.filterVariant
        if (colType === "json")
            return {
                column_field: "detail",
                operatorValue:
                    operatorValue === "contains" ? "@>" : operatorValue,
                value:
                    operatorValue === "empty" || operatorValue === "notEmpty"
                        ? ""
                        : `{"${item.id}":"${item.value}"}`,
            }

        return {
            column_field: item.id,
            operator_value:
                operatorValue === "inBetween" ? "between" : operatorValue,
            value:
                operatorValue === "empty" || operatorValue === "notEmpty"
                    ? ""
                    : item.value,
        }
    })

export default filterModel
