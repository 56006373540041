import {
    MERCHANT_ADMIN_BASE_URL,
    SSO_API_BASE_URL,
    SSO_CLIENT_ID,
} from "./config"

const LoginRedirect = (prompt: string) => {
    window.location.href = `${SSO_API_BASE_URL}/v1/oauth/authorize?client_id=${SSO_CLIENT_ID}&redirect_uri=${MERCHANT_ADMIN_BASE_URL}/authorize&scope=openid&state=state&response_type=code&prompt=${prompt}`
}

export default {
    LoginRedirect,
}
