import { useContext } from "react"
import { MerchantInfo } from "../pages/userMerchants"

const useAccessControl = () => {
    const { userPermissions } = useContext(MerchantInfo) as any

    const hasPermission = (permission: string): boolean =>
        userPermissions?.some(
            (el: { name: string }) =>
                el.name === permission || el.name === "manage-all"
        ) || false

    /* Permissions List */
    const permissions = {
        // Merchants
        createMerchant: hasPermission("Create Merchant Account"),
        updateMerchant: hasPermission("Update Merchant Details"),
        addMerchantBankAcc: hasPermission("Add merchant bank account"),
        updateMerchantBankAcc: hasPermission("Update merchant's bank account"),

        // Products
        createProduct: hasPermission("Create Product"),
        productsList: hasPermission("List All Products"),
        productDetails: hasPermission("Get Product Details"),
        updateProduct: hasPermission("Update Product"),
        deleteProduct: hasPermission("Delete Product"),

        // Prices/Subscription Plans
        createProductPrices: hasPermission("Create Price List for Product"),
        productPricesList: hasPermission("List All Product prices"),
        updatePrice: hasPermission("Update Price from Price List"),
        deletePrice: hasPermission("Delete Price from Price List"),

        // Payment intents
        createPaymentIntents: hasPermission("create-payment-intents"),
        paymentIntentsList: hasPermission("get-payment-intents"),

        // Discounts
        createDiscounts: hasPermission("Create Discount"),
        discountDetails: hasPermission("Get Discount Detail"),
        updateDiscount: hasPermission("Update Discount Details"),
        discountsList: hasPermission("List All Discounts"),

        // User Subscriptions
        cancelUserSubscription: hasPermission("Cancel Subscription"),
        userSubscriptionsList: hasPermission("List Subscriptions"),

        // Customers
        customersList: hasPermission("List All Customers"),

        // Payment
        paymentsList: hasPermission("List All payments"),
        initiateTransferRequest: hasPermission("Initiate Transfer Request"),
        consfirmTransferRequest: hasPermission("Confirm Transfer Request"),

        // System Users
        systemUsersList: hasPermission("get-users"),
        getUserByPhone: hasPermission("get user by phone"),

        // Roles
        createRoles: hasPermission("create role"),
        updateRole: hasPermission("update role"),
        deleteRole: hasPermission("delete role"),
        rolesList: hasPermission("get all roles"),
        revokeRole: hasPermission("revoke role"),
        roleDetails: hasPermission("get role details"),
        assignRole: hasPermission("assign role to user"),

        // Permissions
        getAllPermissions: hasPermission("get all permissions"),
    }

    return { ...permissions }
}

export default useAccessControl
