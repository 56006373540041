import React, { useContext, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import {
    Box,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    Grid,
    TextField,
    Typography,
} from "@mui/material"
import { useSnackbar } from "notistack"
import { CloudUpload } from "@mui/icons-material"

import { MerchantInfo } from "./userMerchants"
import Dropzone from "../component/dragAndDrop"
import useBreakPoints from "../hooks/useBreakPoints"
import useCreateEntity from "../hooks/useCreateEntity"
import useUpdateEntity from "../hooks/useUpdateEntity"

// Merchant Compliance Data Component
function ComplianceData() {
    const { selectedMerchant, setSelectedMerchant } = useContext(MerchantInfo) as any

    // USESTATE
    const [files, setFiles] = useState([]) as any
    const [licenseFileName, setLicenseFileName] = useState()
    const [logoErrorMessage, setLogoErrorMessage] = useState<string>("")

    const { sm } = useBreakPoints()
    // HOOK FORM
    const {
        control,
        handleSubmit,
        formState: { dirtyFields, errors },
        setValue,
        watch,
    } = useForm({
        mode: "onChange",
    }) as any

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // MUTATION
    const {
        mutate,
        isLoading,
        data: updatedData,
    } = useUpdateEntity(`merchants/${selectedMerchant?.id}`) as any
    const { mutate: logoUpload } = useCreateEntity(`/assets`)
    const {
        mutate: licenseUpload,
        isLoading: licenseUploadIsLoading,
        data: licenseUploadFile,
    } = useCreateEntity(`assets`) as any
    
    // HELPERS and EVENT HANDLERS
    const handleLicenseFileUpload = (fileInput: any) => {
        setLicenseFileName(fileInput?.name)
        const licenseFormData = new FormData()
        licenseFormData.set("asset", fileInput)
        licenseFormData.set("type", "merchant_license")
        licenseUpload(licenseFormData)
    }

    const onSubmit = (form: any) => {
        if (logoErrorMessage !== "") {
            return
        }
        if (files?.length !== 1 && !watch("logo")) {
            enqueueSnackbar("Merchant logo is required!", {
                variant: "error",
            })
            return
        }

        if (files?.[0]?.path) {
            const formData = new FormData()
            formData.append("asset", files[0])
            formData.append("type", "merchant_logo")

            logoUpload(formData, {
                onSuccess: (data) => {
                    setValue("logo", data?.data?.data)
                    return mutate({
                        ...form,
                        logo: data?.data?.data,
                        license_document: licenseUploadFile?.data?.data,
                    })
                },
                onError: (logoError) =>
                    enqueueSnackbar(
                        logoError?.response?.data?.error?.message ||
                            logoError?.message ||
                            "Network Error!",
                        {
                            variant: "error",
                        }
                    ),
            })
        } else {
            mutate({
                ...form,
                license_document: licenseUploadFile?.data?.data,
            })
        }
    }

    // USEEFFECT
    useEffect(() => {
        if (
            files?.length > 0 &&
            !files?.[0]?.name?.match(/\.(png|jpg|jpeg)$/)
        ) {
            setFiles([])
            setValue("logo", null)
            setLogoErrorMessage(
                "Merchant logo must be one of the following types: png, jpg or jpeg!"
            )
        }
        if (files?.[0]?.name?.match(/\.(png|jpg|jpeg)$/))
            setLogoErrorMessage("")
    }, [files])

    useEffect(() => {
        if (licenseUploadFile) {
            setValue("license_document", licenseFileName)
        }
    }, [licenseUploadFile])
    useEffect(() => {
        if (selectedMerchant) {
            Object.keys(selectedMerchant).forEach((key) => {
                setValue(key, selectedMerchant[key])
            })
        }
    }, [selectedMerchant])
    useEffect(() => {
        if (updatedData) {
            window.history.back()
            setSelectedMerchant(updatedData?.data?.data)
        }
    }, [updatedData])

    // RENDER
    return (
        <Drawer
            open={true}
            anchor="right"
            onClose={() => window.history.back()}
            PaperProps={{
                style: {
                    width: sm ? "50%" : "100%",
                },
            }}
        >
            <DialogTitle
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Box sx={{ ml: "0.8em" }}>
                    <Typography
                        sx={{
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        {" "}
                        Merchant Compliance
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        mx: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            mt: 2,
                        }}
                    >
                        <Dropzone
                            files={
                                files.length === 0 && logoErrorMessage === ""
                                    ? `${selectedMerchant?.logo}`
                                    : files?.[0]?.preview
                            }
                            setFiles={setFiles}
                            errors={logoErrorMessage}
                        />
                    </Box>
                    <Grid container columnSpacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: "Trade name is required",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="Trade Name"
                                        fullWidth
                                        margin="normal"
                                        autoComplete="name"
                                        error={!!errors?.name}
                                        helperText={
                                            errors?.name
                                                ? errors.name.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 1.5,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="merchant_name"
                                control={control}
                                rules={{
                                    required: "Manager/Business is required",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="Manager/Business"
                                        fullWidth
                                        margin="normal"
                                        autoComplete="merchant_name"
                                        error={!!errors?.merchant_name}
                                        helperText={
                                            errors?.merchant_name
                                                ? errors.merchant_name.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 1.5,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="phone_number"
                                control={control}
                                rules={{
                                    required: "Merchant Phone is required",
                                    minLength: 9,
                                    maxLength: 13,
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        type="number"
                                        label="Phone Number"
                                        margin="normal"
                                        fullWidth
                                        error={!!errors?.phone_number}
                                        helperText={
                                            errors?.phone_number
                                                ? errors.phone_number?.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 0.5,
                                            mr: -2,
                                            flex: 2,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: "Merchant email is required",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="Email"
                                        fullWidth
                                        margin="normal"
                                        autoComplete="email"
                                        error={!!errors?.email}
                                        helperText={
                                            errors?.email
                                                ? errors.email.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 1.5,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="address"
                                control={control}
                                rules={{
                                    required: "Merchant address is required.",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        multiline
                                        label="Address"
                                        margin="normal"
                                        fullWidth
                                        error={!!errors?.address}
                                        helperText={
                                            errors?.address
                                                ? errors.address?.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 0.5,
                                            mr: 1,
                                            flex: 1,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="tin_number"
                                control={control}
                                rules={{
                                    required: "Merchant TIN number is required",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="TIN Number"
                                        fullWidth
                                        autoComplete="tin_number"
                                        error={!!errors?.tin_number}
                                        helperText={
                                            errors?.tin_number
                                                ? errors.tin_number.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 1.5,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="license_number"
                                control={control}
                                rules={{
                                    required:
                                        "Merchant license number is required",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="License Number"
                                        fullWidth
                                        autoComplete="license_number"
                                        error={!!errors?.license_number}
                                        helperText={
                                            errors?.license_number
                                                ? errors.license_number.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 1.5,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="license_document"
                                control={control}
                                rules={{
                                    required:
                                        "Merchant license document is required",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="License Document"
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        autoComplete="license_document"
                                        error={!!errors?.license_document}
                                        helperText={
                                            errors?.license_document &&
                                            dirtyFields?.license_document ===
                                                undefined
                                                ? errors.license_document
                                                      .message
                                                : null
                                        }
                                        sx={{
                                            mt: 1.5,
                                            mb: 0.5,
                                            mr: 1,
                                            flex: 1,
                                        }}
                                        {...field}
                                        InputProps={{
                                            endAdornment: (
                                                <label htmlFor="license-file-input">
                                                    <Button
                                                        sx={{
                                                            mr: -2,
                                                        }}
                                                        component="label"
                                                        disabled={
                                                            licenseUploadIsLoading
                                                        }
                                                    >
                                                        {licenseUploadIsLoading ? (
                                                            <CircularProgress
                                                                size={20}
                                                            />
                                                        ) : (
                                                            <CloudUpload />
                                                        )}
                                                        <input
                                                            id="license-file-input"
                                                            type="file"
                                                            hidden
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                handleLicenseFileUpload(
                                                                    e.target
                                                                        .files[0]
                                                                )
                                                            }
                                                        />
                                                    </Button>
                                                </label>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="callback_url"
                                control={control}
                                rules={{
                                    required: "Callback URL is required.",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        multiline
                                        label="Callback URL"
                                        margin="normal"
                                        fullWidth
                                        error={!!errors?.callback_url}
                                        helperText={
                                            errors?.callback_url
                                                ? errors.callback_url?.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 0.5,
                                            mr: 1,
                                            flex: 1,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="return_url"
                                control={control}
                                rules={{
                                    required: "Return URL is required.",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        multiline
                                        label="Return URL"
                                        margin="normal"
                                        fullWidth
                                        error={!!errors?.return_url}
                                        helperText={
                                            errors?.return_url
                                                ? errors.return_url?.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 0.5,
                                            mr: -2,
                                            flex: 2,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Controller
                                name="detail"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        multiline
                                        label="Description"
                                        margin="normal"
                                        fullWidth
                                        rows={3}
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 0.5,
                                            mr: -2,
                                            flex: 2,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    mt: 1,
                    pr: 3,
                    pt: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        ":hover": { bgcolor: "#e4e4e4", color: "primary.main" },
                        backgroundColor: "#e4e4e4",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                        color: "primary.main",
                    }}
                    onClick={() => window.history.back()}
                >
                    <Typography>Cancel</Typography>
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        bgcolor: "primary.main",
                        ":hover": { bgcolor: "primary.main" },
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    disabled={isLoading}
                >
                    <Typography sx={{ color: "secondary.main" }}>
                        Submit
                    </Typography>
                    {isLoading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: "black",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                            }}
                        />
                    )}
                </Button>
            </DialogActions>
        </Drawer>
    )
}
export default ComplianceData
