import { Navigate, useLocation } from "react-router-dom"
import useAuth from "../hooks/useAuth"

interface PrivateRouteProps {
    children: any
}
function PrivateRoute({ children }: PrivateRouteProps) {
    const { auth }: any = useAuth()
    const { pathname } = useLocation()

    if (pathname.includes("register")) {
        window.localStorage.setItem("registered", "false")
    }

    if (!auth.access_token) {
        return <Navigate to="/login" />
    }
    return children
}

export default PrivateRoute
