import { useMemo, useCallback, useState } from "react"
import { Link } from "react-router-dom"
import { Box, Button, Chip, IconButton, Tooltip } from "@mui/material"
import { Add, Delete, Visibility } from "@mui/icons-material"
import MaterialReactTable, {
    MRT_ColumnDef,
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import {
    ColumnFiltersState,
    Getter,
    PaginationState,
    SortingState,
} from "@tanstack/react-table"
import { DateTime } from "luxon"

import useFetchEntitiesPerPage from "../../hooks/useFetchEntitiesPerPage"
import filterModel from "../../hooks/filterModel"

import CustomFilterPanel from "../../component/customFilterPanel"
import CustomDateFilter from "../../component/customDateFilter"
import StatusButton from "../../component/statusButton"
import AddDiscount from "./createDiscount"

function Discounts() {
    const [columnFilters, setColumnFilters] =
        useState<ColumnFiltersState>() as any
    const [columnFilterFns, setColumnFilterFns] = useState({
        created_at: "is",
        code: "contains",
        status: "is",
        updated_at: "is",
    }) as any
    const [globalFilter, setGlobalFilter] = useState<any>()
    const [sorting, setSorting] = useState<SortingState>() as any
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 15,
    })
    const [open, setOpen] = useState<boolean>(false)

    const sort = useMemo(
        () =>
            sorting?.map(
                (item: {
                    id?: string
                    field?: string
                    sort?: string
                    desc?: string
                }) => ({
                    field: item?.id || item?.field,
                    sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
                })
            ),
        [sorting]
    )
    const {
        data: discounts,
        isLoading,
        refetch,
    } = useFetchEntitiesPerPage({
        endPoint: "discounts",
        page: pagination.pageIndex,
        perPage: pagination.pageSize,
        filterModel: filterModel(columnFilterFns, columnFilters) as any,
        sortModel: sort,
        searchText: globalFilter,
    }) as any

    // Event Handlers & Helpers
    const handleModalClose = () => {
        setOpen(false)
    }
    const statusButton = useCallback(
        ({ row }: { row: { original: any } }) => (
            <StatusButton status={row.original.status} />
        ),
        []
    )
    const actions = useCallback(
        ({ row }: { row: { original: any } }) => (
            <Box>
                <Tooltip title="View discount">
                    <Link
                        to={`${row.original.code}/discounts`}
                        state={row.original}
                    >
                        <IconButton sx={{ color: "primary.main" }}>
                            <Visibility />
                        </IconButton>
                    </Link>
                </Tooltip>
                <Tooltip title="Delete discount">
                    <IconButton color="error">
                        <Delete />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        []
    )
    const method = useCallback(
        ({ row }: { row: { original: any } }) => (
            <Box
                sx={{
                    display: "inline-block",
                    justifyContent: "start",
                    alignItems: "flex-start",
                    fontWeight: "bold",
                }}
            >
                {row?.original?.method.length === 0
                    ? "-"
                    : row?.original?.method?.map((price: any) =>
                          price.name ? (
                              <Chip
                                  key={price?.id}
                                  label={price?.name}
                                  sx={{
                                      my: 0.5,
                                      mr: 0.5,
                                      color: "primary.main",
                                  }}
                              />
                          ) : (
                              "-"
                          )
                      )}
            </Box>
        ),
        []
    )
    const dateFilter = useCallback(
        (dateProps: any) => <CustomDateFilter {...dateProps} />,
        []
    )

    // DATA STRUCTURE
    const columns = useMemo<MRT_ColumnDef<object>[]>(
        () => [
            {
                size: 300,
                accessorKey: "created_at",
                header: "Registered Time",
                filterVariant: "date" as any,
                align: "center",
                renderColumnFilterModeMenuItems: CustomFilterPanel,
                Cell: ({ cell }: { cell: { getValue: Getter<string> } }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: dateFilter as any,
            },
            {
                size: 240,
                accessorKey: "code",
                header: "Code",
                align: "center",
                renderColumnFilterModeMenuItems: CustomFilterPanel,
            },
            {
                size: 220,
                accessorKey: "status",
                header: "Status",
                filterVariant: "select" as any,
                filterSelectOptions: ["ACTIVE", "PENDING", "INACTIVE"],
                Cell: statusButton,
                renderColumnFilterModeMenuItems: CustomFilterPanel,
                align: "center",
            },
            {
                size: 300,
                accessorKey: "updated_at",
                header: "Updated At",
                filterVariant: "date" as any,
                align: "center",
                renderColumnFilterModeMenuItems: CustomFilterPanel,
                Cell: ({ cell }: { cell: { getValue: Getter<string> } }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: dateFilter as any,
            },
            {
                size: 240,
                accessorKey: "actions",
                header: "Actions",
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
                align: "center",
                Cell: actions,
            },
        ],
        [actions, dateFilter, method, statusButton]
    )
    return (
        <Box sx={{ height: "100%" }}>
            {open && (
                <AddDiscount
                    handleModalClose={handleModalClose}
                    refetch={refetch}
                    open={open}
                />
            )}
            <Box sx={{ flex: 1, height: "100%" }}>
                <MaterialReactTable
                    data={discounts ? discounts.data : []}
                    columns={columns}
                    initialState={{
                        columnPinning: {
                            left: ["mrt-row-select"],
                            right: ["actions"],
                        },
                    }}
                    enableColumnResizing
                    enableColumnFilterModes
                    enableStickyHeader
                    enableColumnOrdering
                    enableRowSelection
                    enablePinning
                    manualFiltering
                    manualPagination
                    manualSorting
                    filterFns={{
                        after: (row: any, filterValue) =>
                            row.customField === filterValue,
                    }}
                    muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                        helperText: `Filter Mode: ${
                            columnFilterFns[column?.id]
                        }`,
                    })}
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setColumnFilterFns}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    renderTopToolbarCustomActions={() => (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                size="small"
                                sx={{
                                    bgcolor: "primary.main",
                                    color: "secondary.main",
                                    px: 2,
                                    py: 1,
                                    mx: 1,
                                    boxShadow: 2,
                                    " :hover": {
                                        bgcolor: "primary.main",
                                        color: "secondary.main",
                                    },
                                    textTransform: "none",
                                    spacing: 2,
                                }}
                                onClick={() => setOpen(true)}
                            >
                                <Add sx={{ mr: 1 }} />
                                Add Discount
                            </Button>
                        </Box>
                    )}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <MRT_ToggleFiltersButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <MRT_FullScreenToggleButton table={table} />
                        </>
                    )}
                    muiTableContainerProps={{
                        sx: { maxHeight: `calc(100vh - 225px)` },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            "& .Mui-TableHeadCell-Content": {
                                justifyContent: "space-between",
                            },
                        },
                    }}
                    muiTableBodyCellProps={({ table, column }) => {
                        return {
                            sx: {
                                "&.MuiTableCell-root": {
                                    boxShadow:
                                        table.getState().columnPinning
                                            ?.right?.[0] === column?.id
                                            ? "-7px 0px 10px -1.7px lightgray"
                                            : table
                                                  .getState()
                                                  .columnPinning?.left?.some(
                                                      (el) => el === column.id
                                                  )
                                            ? "7px 0px 10px -1.7px lightgray"
                                            : "none",
                                },
                            },
                        }
                    }}
                    rowCount={discounts?.meta_data?.total ?? 0}
                    state={{
                        columnFilters: columnFilters || [],
                        columnFilterFns,
                        globalFilter: globalFilter || "",
                        isLoading,
                        pagination,
                        sorting: sorting || [],
                        showSkeletons: isLoading,
                    }}
                />
            </Box>
        </Box>
    )
}

export default Discounts
