import { Duration } from "luxon"

function convertToHours(timeString: string) {
    const parts = timeString.split(":")
    const hours = parseInt(parts[0])
    const minutes = parseInt(parts[1])
    const seconds = parseInt(parts[2])

    const duration = Duration.fromObject({
        hours,
        minutes,
        seconds,
    })

    return duration.as("hours")
}
export default convertToHours
