import { useContext, useEffect } from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { useMutation } from "@tanstack/react-query"
import { CircularProgress, Typography } from "@mui/material"
import { useSnackbar } from "notistack"

import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import AuthContext from "../../context/authProvider"

interface DeleteDialog {
    id: string
    open: boolean
    setOpen: (item: boolean) => void
    refetch: () => void
}
export default function DeleteRole({
    id,
    open,
    setOpen,
    refetch,
}: DeleteDialog) {
    const { merchantId } = useContext(AuthContext) as any

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"))

    const { enqueueSnackbar } = useSnackbar()

    const axiosPrivate = useAxiosPrivate()

    const { isLoading, mutate, data } = useMutation(
        () => axiosPrivate.delete(`/merchants/${merchantId}/roles/${id}`),
        {
            onError: (error: any) => {
                enqueueSnackbar(
                    error?.response?.data?.error?.field_error?.[0]
                        ?.description ||
                        error?.response?.data?.error?.message ||
                        "Request Failed",
                    {
                        variant: "error",
                    }
                )
                refetch()
            },
        }
    )

    const handleDelete = () => {
        mutate()
    }

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        if (data) {
            enqueueSnackbar("Role deleted successfully", {
                variant: "success",
            })
            setOpen(false)
            refetch()
        }
    }, [data])

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="delete-role-dialog-title"
            >
                <DialogTitle
                    id="delete-role-dialog-title"
                    sx={{ bgcolor: "#fafafa" }}
                >
                    <Typography
                        variant="h5"
                        sx={{ textAlign: "center", fontWeight: "bold" }}
                    >
                        Delete Role
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ m: 2 }}>
                    <DialogContentText>
                        <Typography variant="body1">
                            Are you sure, do you want to delete the selected
                            role?
                            <br />
                            This is an irriversible action!
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ bgcolor: "#fafafa" }}>
                    <Button variant="contained" autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleDelete}
                        autoFocus
                        color="error"
                    >
                        Delete
                        {isLoading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: "black",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                }}
                            />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
