import { Avatar, Box } from "@mui/material"

const PaymentMethods = ({
    rowData,
    bankData,
}: {
    rowData: string[]
    bankData: any[]
}) => {
    return (
        <Box
            sx={{
                display: "inline-block",
                justifyContent: "start",
                alignItems: "flex-start",
                fontWeight: "bold",
            }}
        >
            {rowData?.length === 0
                ? "-"
                : rowData?.map((method: string) =>
                      method
                          ? bankData
                                ?.filter(
                                    (bank: { id: string }) =>
                                        bank?.id === method
                                )
                                ?.map(
                                    (item: {
                                        id: string
                                        name: string
                                        logo: string
                                    }) => (
                                        <Box
                                            key={item.id}
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                bgcolor: "#fff",
                                                borderRadius: 6,
                                                py: 1,
                                                px: 2,
                                                mb: 0.5,
                                                mr: 0.5,
                                            }}
                                        >
                                            <Avatar
                                                sx={{
                                                    width: 30,
                                                    height: 30,
                                                    mr: 1,
                                                }}
                                                src={item?.logo}
                                            />
                                            {item?.name?.replace("_", " ")}
                                        </Box>
                                    )
                                )
                          : "-"
                  )}
        </Box>
    )
}

export default PaymentMethods
