import { useEffect, useRef } from "react"
import {
    Alert,
    AlertTitle,
    Box,
    CircularProgress,
    FormControlLabel,
    Switch,
} from "@mui/material"
import { useIsMutating } from "@tanstack/react-query"
import { Pending } from "@mui/icons-material"
import CustomAlert from "./customAlert"

const color = {
    active: "#529a61",
    inactive: "#dd4848",
    pending: "gray",
}

function StatusButton({
    status,
    onChange,
    loading,
    disabled,
}: {
    status: string
    onChange?: (item: any) => void
    loading?: boolean
    disabled?: boolean
}) {
    const pending = useRef(false)

    const mutations = useIsMutating()

    useEffect(() => {
        if (!loading || mutations !== 0) pending.current = false
    }, [loading, mutations])

    const handleChange = (event: any) => {
        const data = event.target.checked
        if (onChange) {
            onChange(data)
        }
        pending.current = true
    }
    const label = () => {
        if (status === "ACTIVE") {
            return <CustomAlert status="ACTIVE" variant="standard" />
        }
        if (status === "PENDING") {
            return (
                <Alert
                    icon={<Pending sx={{ color: "#fbcf3b" }} />}
                    sx={{
                        bgcolor: "inherit",
                        mt: "0.5em",
                        color: "#fbcf3b",
                    }}
                >
                    <AlertTitle sx={{ mt: 0 }}>{status}</AlertTitle>
                </Alert>
            )
        }
        if (status === "INACTIVE" || status === "EXPIRED") {
            return <CustomAlert status="INACTIVE" variant="standard" />
        }
        if (status === "CANCELED") {
            return <CustomAlert status="CANCELED" variant="standard" />
        }
    }
    return (
        <Box
            sx={{
                width: "fit-content",
                height: "fit-content",
                position: "relative",
            }}
        >
            <CircularProgress
                size="1.5em"
                sx={{
                    position: "absolute",
                    top: ".7em",
                    left: "9ch",
                    fontWeight: "bold",
                    color: "gray",
                    display: pending.current ? "block" : "none",
                }}
            />
            <FormControlLabel
                labelPlacement="start"
                label={label()}
                checked={status === "ACTIVE"}
                disabled={pending.current || disabled}
                onChange={handleChange}
                control={
                    <Switch
                        disableRipple
                        disabled={pending.current}
                        size="small"
                        sx={{
                            "& .MuiSwitch-switchBase": {
                                "&.Mui-checked": {
                                    "& + .MuiSwitch-track": {
                                        backgroundColor: "#c3eacb",
                                    },
                                    "& .MuiSwitch-thumb": {
                                        color: color.active,
                                    },
                                    "&.Mui-disabled + .MuiSwitch-thumb": {
                                        backgroundColor: "lightseagreen",
                                        color: "lightgreen",
                                        opacity: 0.6,
                                    },
                                },
                            },
                            "& .MuiSwitch-thumb": {
                                color:
                                    status === "PENDING"
                                        ? "#fbcf3b"
                                        : "#f86049b5",
                            },
                            "& .MuiSwitch-track": {
                                backgroundColor: "#d9acac",
                            },
                        }}
                    />
                }
                sx={{
                    backgroundColor:
                        status === "ACTIVE"
                            ? "#e5ffea"
                            : status === "PENDING"
                            ? "#f5f5f5"
                            : "#ffd6d6",
                    opacity: pending.current ? 0.4 : 1,
                    color:
                        status === "ACTIVE"
                            ? color.active
                            : status === "PENDING"
                            ? "#fbcf3b"
                            : "#f86049b5",
                    px: "0.5em",
                    borderRadius: 1,
                    width: "23ch",
                    fontWeight: 900,
                }}
            />
        </Box>
    )
}

export default StatusButton
