import { Alert, AlertTitle, Box } from "@mui/material"
import { Pending } from "@mui/icons-material"
import CustomAlert from "./customAlert"
import { ReactNode } from "react"

function PaymentStatus({
    status,
    variant,
    message,
}: {
    status: string
    variant: any
    message?: ReactNode
}) {
    const label = () => {
        if (status === "SUCCEEDED") {
            return (
                <CustomAlert
                    status="SUCCEEDED"
                    variant={variant}
                    message={message}
                />
            )
        }
        if (status === "PENDING" || status === "PROCESSING") {
            return (
                <Alert
                    variant={variant}
                    icon={<Pending sx={{ color: "#fbcf3b" }} />}
                    sx={{
                        bgcolor: "inherit",
                        mt: "0.5em",
                        color: "#fbcf3b",
                    }}
                >
                    <AlertTitle sx={{ mt: 0 }}>{status}</AlertTitle>
                    {message ?? ""}
                </Alert>
            )
        }
        if (status === "FAILED") {
            return (
                <CustomAlert
                    status="FAILED"
                    variant={variant}
                    message={message}
                />
            )
        }
    }
    return (
        <Box
            sx={{
                width: "fit-content",
                height: "fit-content",
                position: "relative",
            }}
        >
            {label()}
        </Box>
    )
}

export default PaymentStatus
