import { useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { useSnackbar } from "notistack"
import { DateTime } from "luxon"
import {
    Avatar,
    Box,
    Chip,
    CircularProgress,
    Divider,
    Grid,
    Typography,
} from "@mui/material"
import { FiberManualRecord } from "@mui/icons-material"

import convertToHours from "../../utils/convertToHours"
import { CapitalizeWordAndAddLy } from "../../utils/capitalizeWordAndAddLy"

import PaymentStatus from "../../component/paymentStatus"
import useFetchEntities from "../../hooks/useFetchEntities"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"

function UserSubscriptionDetails() {
    const { userSubId } = useParams()

    const axiosPrivate = useAxiosPrivate()

    const { enqueueSnackbar } = useSnackbar()

    const { isLoading: loadingBanks, data: banks } = useFetchEntities({
        endPoint: "/payment-methods",
    }) as any

    const { isLoading: loading, data } = useQuery({
        queryKey: [],
        queryFn: () =>
            axiosPrivate
                .get(`/subscriptions/${userSubId}`)
                .then((response) => response.data.data),

        onError: (error: any) => {
            const fieldError =
                error.response?.data?.error?.field_error?.[0]?.description
            const errorMessage = error.response?.data?.error?.message
            enqueueSnackbar(fieldError || errorMessage || "Request Failed", {
                variant: "error",
            })
        },
    })

    const paymentMethod = (id: string) =>
        banks?.data?.filter((item: any) => item?.id === id)[0]

    return loading || loadingBanks ? (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <CircularProgress
                size={30}
                sx={{
                    color: "black",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                }}
            />
        </Box>
    ) : (
        <Grid
            container
            spacing={3}
            sx={{
                pl: { xs: 0 },
                pt: "1.5em",
            }}
        >
            <Grid item md={8} xs={12}>
                <Grid container sx={{ gap: "1em" }}>
                    <Grid
                        item
                        xs={12}
                        sm={5.75}
                        sx={{ boxShadow: 2, p: "1em" }}
                    >
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                            Customer
                        </Typography>
                        <Divider sx={{ mr: 2, my: "0.5em" }} />
                        <Box
                            sx={{
                                display: "flex",
                                gap: 1,
                                alignItems: "center",
                                px: 2,
                            }}
                        >
                            <Typography>
                                {data?.customer?.customer_full_name}
                            </Typography>
                            <FiberManualRecord
                                sx={{
                                    color: "secondary.main",
                                    fontSize: 10,
                                }}
                            />
                            <Typography>
                                {data?.customer?.customer_phone_number}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={5.75}
                        sx={{ boxShadow: 2, p: "1em" }}
                    >
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                            Product
                        </Typography>
                        <Divider sx={{ mr: 2, my: "0.5em" }} />
                        <Box
                            sx={{
                                display: "flex",
                                gap: 1,
                                alignItems: "center",
                                px: 2,
                            }}
                        >
                            <Typography>{data?.product?.name}</Typography>
                        </Box>
                    </Grid>
                    <Divider />
                    <Grid
                        item
                        xs={12}
                        sm={11.85}
                        lg={11.75}
                        sx={{ boxShadow: 2 }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                borderColor: "primary.main",
                                gap: "0.5em",
                                p: "1em",
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{ fontWeight: "bold" }}
                            >
                                Subscription Plan
                            </Typography>
                            <Divider sx={{ mr: 2 }} />
                            <Typography
                                variant="body1"
                                sx={{ fontWeight: "bold", px: 2 }}
                            >
                                {data.price.name}
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 2,
                                    px: 2,
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    sx={{ fontWeight: "bold" }}
                                >
                                    {CapitalizeWordAndAddLy(
                                        data.price.frequency ?? ""
                                    )}
                                </Typography>
                                <FiberManualRecord
                                    sx={{
                                        color: "secondary.main",
                                        fontSize: 10,
                                    }}
                                />
                                <Box
                                    sx={{
                                        display: "flex",
                                        gap: "0.2em",
                                        alignItems: "baseline",
                                    }}
                                >
                                    <Typography variant="body1">
                                        {data.price.amount}
                                    </Typography>
                                    <Typography sx={{ fontSize: 12 }}>
                                        {data.currency.code}
                                    </Typography>
                                </Box>
                                <Chip
                                    variant="filled"
                                    label={
                                        <Box
                                            sx={{
                                                display: "flex",
                                                gap: 1,
                                                p: 0.5,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: "secondary.main",
                                                }}
                                            >
                                                Recurring
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: "primary.main",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                ({data?.price?.recurring_cycle})
                                            </Typography>
                                        </Box>
                                    }
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "0.5em",
                                        px: 2,
                                        pb: 2,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "0.2em",
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: "bold" }}>
                                            Grace Period
                                        </Typography>
                                        <Typography>
                                            {data.price?.grace_period
                                                ? `(${convertToHours(
                                                      data.price?.grace_period
                                                  )} hr)`
                                                : "(0 hr)"}
                                        </Typography>
                                    </Box>
                                    <FiberManualRecord
                                        sx={{
                                            color: "secondary.main",
                                            fontSize: 10,
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "0.2em",
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: "bold" }}>
                                            Expire Date
                                        </Typography>
                                        <Typography>
                                            (
                                            {DateTime.fromISO(
                                                data.price.expiration
                                            ).toLocaleString(
                                                DateTime.DATETIME_MED_WITH_SECONDS
                                            )}
                                            )
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                sm={11.5}
                md={4}
                sx={{
                    boxShadow: 2,
                    pb: "1.5em",
                    mt: "1.5em",
                    ml: { sm: "1.5em", xs: "1.5em", md: 0 },
                }}
            >
                <Typography variant="h6">Payment History</Typography>
                <Box
                    sx={{
                        display: "flex",
                        overflow: "auto",
                        width: "100%",
                        maxHeight: { sm: `calc(100vh - 160px)`, xs: `100%` },
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.5em",
                            minWidth: "100%",
                        }}
                    >
                        {data.uspm?.map((item: any) => (
                            <PaymentStatus
                                key={item.id}
                                status={item.status}
                                variant="outlined"
                                message={
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "start",
                                            flexDirection: "column",
                                            color: "primary.main",
                                            fontWeight: "bold",
                                            minWidth: "320px",
                                            gap: "0.5em",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                gap: "0.5em",
                                            }}
                                        >
                                            <Typography color="grey">
                                                Total Cycles Paid:
                                            </Typography>
                                            <Typography>
                                                {item.total_cycles_paid}{" "}
                                                {data?.currency?.code}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "0.5em",
                                            }}
                                        >
                                            <Typography color="grey">
                                                Payment Method:
                                            </Typography>
                                            <Avatar
                                                sx={{
                                                    width: 30,
                                                    height: 30,
                                                    mr: "0.1em",
                                                }}
                                                src={
                                                    paymentMethod(
                                                        item?.payment_method_id
                                                    )?.logo
                                                }
                                            />
                                            {paymentMethod(
                                                item?.payment_method_id
                                            )?.name?.replace("_", " ")}
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                gap: "0.5em",
                                            }}
                                        >
                                            <Typography color="grey">
                                                Time:
                                            </Typography>
                                            <Typography>
                                                {DateTime.fromISO(
                                                    item.updated_at
                                                ).toLocaleString(
                                                    DateTime.DATETIME_MED_WITH_SECONDS
                                                )}
                                            </Typography>
                                        </Box>
                                    </Box>
                                }
                            />
                        ))}
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default UserSubscriptionDetails
