import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Container,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Delete, Edit, FiberManualRecord } from "@mui/icons-material"
import { CapitalizeWordAndAddLy } from "../../utils/capitalizeWordAndAddLy"
import { DateTime } from "luxon"
import { Controller, useForm } from "react-hook-form"
import { useContext, useEffect, useState } from "react"
import { useSnackbar } from "notistack"
import { useMutation, useQuery } from "@tanstack/react-query"

import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import useAccessControl from "../../hooks/useAccessControl"
import DeletePrice from "../prices/deletePrice"
import ErrorPage, { ErrorResponse } from "../../component/errorPage"
import axios, { AxiosError } from "axios"
import AddPrices from "../prices/addPrices"
import UpdatePrice from "../prices/updatePrice"
import convertToHours from "../../utils/convertToHours"
import AuthContext from "../../context/authProvider"

function UpdateProduct() {
    const { createProductPrices, productPricesList, updatePrice, deletePrice } =
        useAccessControl()
    const location = useLocation()
    const { productId } = useParams()
    const { merchantId } = useContext(AuthContext) as any

    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
    const [priceId, setPriceId] = useState<string>("")
    const [openPricing, setOpenPricing] = useState<boolean>(false)
    const [updatePricing, setOpenEditPricing] = useState<boolean>(false)
    const [price, setPrice] = useState<object>()

    const { enqueueSnackbar } = useSnackbar()

    const axiosPrivate = useAxiosPrivate()

    const navigate = useNavigate()

    const {
        isLoading: productIsLoading,
        data: prices,
        error,
        refetch,
    } = useQuery({
        queryKey: [`price_${productId}`],
        queryFn: () =>
            axiosPrivate
                .get(`merchants/${merchantId}/products/${productId}/prices`)
                .then((response) => response.data.data),
        enabled: productPricesList,
    })
    const { mutate, isLoading, data } = useMutation(
        (formData) =>
            axiosPrivate.patch(
                `/merchants/${merchantId}/products/${productId}`,
                formData
            ),
        {
            onError: (error: any) => {
                enqueueSnackbar(
                    error?.response?.data?.error?.field_error?.[0]
                        ?.description ||
                        error?.response?.data?.error?.message ||
                        "Request Failed",
                    {
                        variant: "error",
                    }
                )
            },
        }
    )

    const { handleSubmit, control } = useForm({
        defaultValues: location.state,
    })
    const handleClosePricingDialog = () => {
        setOpenPricing(false)
    }
    const handleUpdatePricingDialog = () => {
        setOpenEditPricing(false)
    }
    const handleEditPricing = (selectedPrice: object) => {
        setOpenEditPricing(true)
        setPrice(selectedPrice)
    }

    useEffect(() => {
        if (data) {
            enqueueSnackbar("Product updated successfully", {
                variant: "success",
            })
            refetch()
        }
    }, [data, refetch])

    // HANDLERS
    const onSubmit = (userInput: any) => {
        mutate(userInput)
    }
    const handleDeletePrice = (id: string) => {
        setOpenDeleteDialog(true)
        setPriceId(id)
    }

    // RENDER
    if (error) {
        const err = error as AxiosError
        if (axios.isAxiosError(err)) {
            const newErr = err?.response?.data as ErrorResponse
            return <ErrorPage {...newErr} />
        }
    }
    if (productIsLoading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    minHeight: "40vh",
                }}
            >
                <CircularProgress
                    size={50}
                    sx={{
                        color: "secondary.main",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                    }}
                />
            </Box>
        )
    }
    if (openPricing)
        return (
            <AddPrices
                productId={productId}
                openDialog={openPricing}
                handleClosePricingDialog={handleClosePricingDialog}
                refetch={refetch}
            />
        )
    if (updatePricing)
        return (
            <UpdatePrice
                price={price}
                openDialog={updatePricing}
                handleClosePricingDialog={handleUpdatePricingDialog}
                refetch={refetch}
            />
        )
    return (
        <Container
            maxWidth={false}
            sx={{
                pb: { md: "5em", xs: "1em" },
                pt: { md: "1em" },
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
            }}
        >
            {openDeleteDialog && (
                <DeletePrice
                    id={priceId}
                    open={openDeleteDialog}
                    setOpen={setOpenDeleteDialog}
                    refetch={refetch}
                />
            )}
            <Grid
                container
                spacing={6}
                justifyContent="space-around"
                sx={{
                    flex: 2,
                    pl: { xs: 0 },
                }}
            >
                <Grid
                    item
                    xs={12}
                    lg={5}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1.5em",
                    }}
                >
                    <Typography variant="body1" sx={{ fontWeight: "500" }}>
                        Product Info
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: ".5em",
                        }}
                    >
                        <TextField
                            value={location?.state?.name}
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            sx={{
                                bgcolor: "#f7f7f8",
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: ".5em",
                        }}
                    >
                        <Controller
                            name="description"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    multiline
                                    sx={{
                                        bgcolor: "#f7f7f8",
                                    }}
                                    rows={3}
                                    fullWidth
                                    {...field}
                                />
                            )}
                        />
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={7}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1.5em",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: {
                                xs: "flex-start",
                                sm: "space-between",
                            },
                            flexDirection: { xs: "column", sm: "row" },
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold", mb: { xs: 1, sm: 0 } }}
                        >
                            Plans
                        </Typography>
                        {createProductPrices && (
                            <Button
                                size="small"
                                variant="contained"
                                sx={{
                                    bgcolor: "primary.main",
                                    ":hover": { bgcolor: "primary.main" },
                                    textTransform: "none",
                                    fontWeight: "bold",
                                    width: 120,
                                }}
                                onClick={() => setOpenPricing(true)}
                            >
                                <Typography sx={{ color: "secondary.main" }}>
                                    Add Pricing
                                </Typography>
                            </Button>
                        )}
                    </Box>
                    <Box
                        sx={{
                            maxHeight: {
                                sm: `calc(100vh - 320px)`,
                                xs: `100%`,
                            },
                            minHeight: "190px",
                            overflow: "auto",
                            width: "100%",
                            pr: 1,
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                            }}
                        >
                            {prices?.map((price: any) => (
                                <Box
                                    key={price.id}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        border: "1px solid",
                                        borderColor: "primary.main",
                                        borderRadius: 2,
                                        gap: "1em",
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontWeight: "bold",
                                            px: 2,
                                            mt: 2,
                                        }}
                                    >
                                        {price.name}
                                    </Typography>
                                    <Divider sx={{ mx: 2 }} />
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 2,
                                            px: 2,
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            sx={{ fontWeight: "bold" }}
                                        >
                                            {CapitalizeWordAndAddLy(
                                                price.frequency
                                            )}
                                        </Typography>
                                        <FiberManualRecord
                                            sx={{
                                                color: "secondary.main",
                                                fontSize: 10,
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                display: "flex",
                                                gap: "0.2em",
                                                alignItems: "baseline",
                                            }}
                                        >
                                            <Typography variant="body1">
                                                {price.amount}
                                            </Typography>
                                            <Typography sx={{ fontSize: 12 }}>
                                                {price.currency.code}
                                            </Typography>
                                        </Box>
                                        <Chip
                                            variant="filled"
                                            label={
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        gap: 1,
                                                        p: 0.5,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: "secondary.main",
                                                        }}
                                                    >
                                                        Recurring
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            color: "primary.main",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        ({price.recurring_cycle}
                                                        )
                                                    </Typography>
                                                </Box>
                                            }
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "0.5em",
                                                px: 2,
                                                pb: 2,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    gap: "0.2em",
                                                }}
                                            >
                                                <Typography
                                                    sx={{ fontWeight: "bold" }}
                                                >
                                                    Grace Period
                                                </Typography>
                                                <Typography>
                                                    (
                                                    {price.grace_period
                                                        ? `${convertToHours(
                                                              price.grace_period
                                                          )} hr`
                                                        : "0 hr"}
                                                    )
                                                </Typography>
                                            </Box>
                                            <FiberManualRecord
                                                sx={{
                                                    color: "secondary.main",
                                                    fontSize: 10,
                                                }}
                                            />
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    gap: "0.2em",
                                                }}
                                            >
                                                <Typography
                                                    sx={{ fontWeight: "bold" }}
                                                >
                                                    Expire Date
                                                </Typography>
                                                <Typography>
                                                    (
                                                    {DateTime.fromISO(
                                                        price.expiration
                                                    ).toLocaleString(
                                                        DateTime.DATETIME_MED_WITH_SECONDS
                                                    )}
                                                    )
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                justifyContent: "center",
                                                alignItems: "center",
                                                pb: 2,
                                                mr: 2,
                                            }}
                                        >
                                            {updatePrice && (
                                                <IconButton
                                                    sx={{
                                                        bgcolor: "#f7f7f8",
                                                        borderRadius: 0,
                                                        px: 1,
                                                    }}
                                                    onClick={() =>
                                                        handleEditPricing(price)
                                                    }
                                                >
                                                    <Edit
                                                        sx={{
                                                            color: "primary.main",
                                                            mx: 0.5,
                                                        }}
                                                    />
                                                </IconButton>
                                            )}
                                            {deletePrice && (
                                                <IconButton
                                                    sx={{
                                                        bgcolor: "#f7f7f8",
                                                        borderRadius: 0,
                                                        px: 1,
                                                    }}
                                                    onClick={() =>
                                                        handleDeletePrice(
                                                            price.id
                                                        )
                                                    }
                                                >
                                                    <Delete
                                                        color="error"
                                                        sx={{ mx: 0.5 }}
                                                    />
                                                </IconButton>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Divider sx={{ mx: "auto", mt: "0.5em" }} />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Box
                        sx={{
                            display: "flex",
                            margin: "auto",
                            justifyContent: "flex-end",
                            alignContent: "baseline",
                            flexDirection: { xs: "column", sm: "row" },
                            mt: -2,
                        }}
                    >
                        <Button
                            variant="contained"
                            onClick={() => navigate("/products")}
                            sx={{
                                marginRight: "1em",
                                bgcolor: "#f7f7f8",
                                color: "primary.main",
                                ":hover": {
                                    bgcolor: "#f7f7f8",
                                },
                                width: { sm: 180, xs: "100%" },
                                p: 1,
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            startIcon={
                                isLoading ? (
                                    <CircularProgress
                                        size="1em"
                                        sx={{ color: "white" }}
                                    />
                                ) : null
                            }
                            sx={{
                                width: { sm: 180, xs: "100%" },
                                mt: { sm: 0, xs: 2 },
                                p: 1,
                            }}
                            variant="contained"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Save
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}

export default UpdateProduct
