import {
    ListItem,
    ListItemIcon,
    ListItemText,
    SxProps,
    Tooltip,
} from "@mui/material"
import { Theme } from "@mui/system"
import { FC, ReactElement } from "react"
import { NavLink, useLocation } from "react-router-dom"

interface LinkProps {
    to: string
    icon: ReactElement
    label: string
    open: boolean
    sx?: SxProps<Theme> | undefined
    handleSelect?: React.MouseEventHandler<HTMLAnchorElement>
}
const Link: FC<LinkProps> = ({ to, icon, label, open, sx, handleSelect }) => {
    const { pathname } = useLocation()
    return (
        <ListItem
            component={NavLink}
            to={to}
            style={{
                color: pathname.split("/")[1] === to ? "#FFAA00" : "",
                backgroundColor:
                    pathname.split("/")[1] === to ? "#1f1e1d" : "#fff",
            }}
            sx={sx}
            onClick={handleSelect}
        >
            <ListItemIcon
                sx={{
                    color:
                        pathname.split("/")[1] === to
                            ? "secondary.main"
                            : "primary.main",
                }}
            >
                <Tooltip
                    title={label}
                    arrow
                    placement={open ? "bottom" : "right"}
                >
                    {icon}
                </Tooltip>
            </ListItemIcon>
            <ListItemText
                sx={{
                    color:
                        pathname.split("/")[1] === to
                            ? "secondary.main"
                            : "primary.main",
                }}
                primary={label}
            />
        </ListItem>
    )
}
export default Link
