import {
    FilledInputProps,
    InputProps,
    OutlinedInputProps,
    TextField,
    TextFieldVariants,
} from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon"
import { ReactNode } from "react"
import { merge } from "lodash"
import { DateTime } from "luxon"

interface CustomDatePickerProps {
    variant: TextFieldVariants
    size: "small" | "medium"
    helperText: ReactNode
    InputAdornmentProps?: any
    inputProps:
        | Partial<OutlinedInputProps>
        | Partial<InputProps>
        | Partial<FilledInputProps>
    value: any
    date1?: string
    date2?: string
    onChange: (date: any) => void
}
function CustomeDatePicker(props: CustomDatePickerProps) {
    const { inputProps, value, date1, date2, onChange, ...otherUserProps } =
        props
    const handleDateChange = (date: any) => {
        onChange(date?.toString())
    }

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
                value={value ? DateTime.fromISO(value) : null}
                disableFuture
                minDate={
                    date2 ? DateTime.fromISO(date2) || new Date() : undefined
                }
                maxDate={
                    date1 ? DateTime.fromISO(date1) || new Date() : undefined
                }
                onChange={handleDateChange}
                slots={{
                    textField: (params) => (
                        <TextField
                            {...params}
                            {...otherUserProps}
                            InputProps={merge(params.InputProps, inputProps)}
                        />
                    ),
                }}
            />
        </LocalizationProvider>
    )
}

export default CustomeDatePicker
