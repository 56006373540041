import {
    Timeline,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
    timelineItemClasses,
} from "@mui/lab"
import { DateTime } from "luxon"
import { MoreVert } from "@mui/icons-material"

interface CustomTimeLineProps {
    from: string
    to: string
    color: string
}

export default function CustomTimeLine(props: CustomTimeLineProps) {
    const { from, to, color } = props
    return (
        <Timeline
            sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                    m: 0,
                },
                minHeight: "3em",
            }}
        >
            <TimelineItem>
                <TimelineSeparator
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <TimelineDot
                        sx={{
                            bgcolor: color,
                            alignSelf: "center",
                            my: "0.005em",
                            height: "0.5em",
                        }}
                    />
                    <MoreVert
                        fontSize="small"
                        sx={{ alignSelf: "center", mb: "-0.3em", color: color }}
                    />
                    <MoreVert
                        fontSize="small"
                        sx={{ alignSelf: "center", color: color }}
                    />
                </TimelineSeparator>
                <TimelineContent>
                    {DateTime.fromISO(from).toFormat("DD")}
                </TimelineContent>
            </TimelineItem>
            <TimelineItem
                sx={{
                    mt: "-1em",
                    minHeight: "1em",
                }}
            >
                <TimelineSeparator>
                    <TimelineDot
                        sx={{
                            bgcolor: color,
                            alignSelf: "center",
                            mx: "0.35em",
                            mt: "0.05em",
                        }}
                    />
                </TimelineSeparator>
                <TimelineContent sx={{ py: 0, mt: "-0.3em" }}>
                    {DateTime.fromISO(to).toFormat("DD")}
                </TimelineContent>
            </TimelineItem>
        </Timeline>
    )
}
