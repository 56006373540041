import { FC } from "react"
import { Avatar, Grid, Typography } from "@mui/material"
import { Box, Container } from "@mui/system"

export interface ErrorResponse {
    error: {
        code: number
        description: string
        message: string
    }
}

// Error Page Component
const ErrorPage: FC<ErrorResponse> = ({ error }) => {
    return (
        <Grid
            container
            sx={{
                minHeight: `calc(100vh - 20em)`,
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Container component="main" maxWidth="lg">
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        px: 2,
                    }}
                >
                    <Avatar
                        sx={{
                            width: 200,
                            height: { sm: 180, xs: 75 },
                            borderRadius: 0,
                        }}
                        alt="logo"
                        src={process.env.PUBLIC_URL + "/assets/logo.png"}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            sx={{
                                display: "inline",
                                fontSize: { sm: 30, xs: 18 },
                                fontWeight: "bold",
                                color: "red",
                                textAlign: "center",
                            }}
                        >
                            An Error Has Occurred.
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                mt: "0.5em",
                            }}
                        >
                            <Typography
                                sx={{
                                    display: "inline",
                                    fontSize: { sm: 30, xs: 14 },
                                    mb: 0.5,
                                }}
                            >
                                {error
                                    ? `${error?.message}` || "Network Error!"
                                    : ""}
                            </Typography>
                            <Typography
                                sx={{
                                    display: "inline",
                                    fontSize: { sm: 30, xs: 14 },
                                    color: "#9f9b94",
                                }}
                            >
                                That is all we know!
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Grid>
    )
}
export default ErrorPage
